import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import USP from "../components/home/usp";
import Button from "../components/buttons/button";

import learnersOne from "../images/learners/learners-hero.png";
import learnersHero from "../images/learners/learner-one.png";
import learnersTwo from "../images/learners/learner-two.png";


function LearnersPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Nubee`,
          `driving school`,
          `learners`,
          `artificial intelligence`,
        ]}
        title="Learners"
      />

      <section className="md:p-0 text-left pt-28 md:pt-24">
        <div className="flex flex-col gap-5 md:flex-row px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
                We got you! Access our free theory material.
              </h1>
              <p className="pt-8 text-black font-body max-w-xl">
                Study smart, not hard.
              </p>
              <p className="pt-8 text-black font-body max-w-xl">
                Log in to the Nubee Dashboard in order to get access to our K53
                Learners licence Study Guides as well as our specially crafted
                test quizes that will get you ready for your big day.
              </p>
              {/* <div className="flex flex-col gap-5 md:w-11/12 relative z-20">
                <USP title="K53 Learners Study Guide"></USP>
                <USP title="Take A Quiz"></USP>
              </div> */}
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={learnersHero}
            className="object-contain w-3/4 md:w-2/6 mx-auto hidden md:block"
          />
        </div>
      </section>

      <section className="mt-14 md:pr-16 bg-nubee-purple py-14">
        <div className="flex flex-col md:flex-row gap-5 gap:md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <img
            className="object-contain w-3/4 md:w-2/4 mx-auto"
            src={learnersOne}
          />
          <div className="text-center md:text-left md:pl-32">
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              Learners K53 study guide.
            </h1>
            <p className="text-black">
              To get your {"learner's"} licence, you must pass a {"learner's"}{" "}
              theory test, which covers the following topics:
            </p>
            <ul className="list-style-none pt-8">
              <li>1. Road traffic signs and signals</li>
              <li>2. Road markings</li>
              <li>3. Speed limits</li>
              <li>4. Alcohol and drug-impaired driving</li>
              <li>5. Vehicle control</li>
              <li>6. Defensive driving</li>
              <li>7. Defensive driving</li>
              <li>8. Vehicle maintenance</li>
              <li>9. Basic first aid.</li>
            </ul>
            <p className="text-black pt-8">
              We have crafted our study material to focus on what they usually
              ask.
            </p>
            <Button
              title="Get Started"
              href="https://app.nubee.co.za/#/auth/login"
            ></Button>
          </div>
        </div>
      </section>

      <section className="mt-10 py-10 md:py-0 md:mt-24 md:px-16">
        <div className="flex flex-col md:flex-row gap-5 md:gap-32 items-center px-6 md:max-w-7xl mx-auto">
          <div className="text-center md:text-left">
            <h1 className="mb-4 text-5xl md:text-6xl font-bold text-black font-heading">
              Test your knowledge
            </h1>
            <p className="text-black">
              As a learner driver, {"it's"} important to regularly test your
              knowledge of road rules and safety procedures. One great way to do
              this is by taking a practice quiz on the dashboard. By logging in
              and completing the quiz, {"you'll"} not only get a better
              understanding of areas where you may need to improve but also gain
              confidence in your abilities. So, make sure to take advantage of
              this valuable resource and take a practice quiz today!
            </p>
            <Button
              title="Take the quiz"
              href="https://app.nubee.co.za/#/quiz"
            ></Button>
          </div>
          <img
            className="order-first md:order-last  w-3/4 md:w-2/4 mx-auto"
            src={learnersTwo}
          />
        </div>
      </section>
    </Layout>
  )
}

export default LearnersPage;
